import React from "react"

var preStyle = {
    whiteSpace: "pre-wrap",
    whiteSpace: "-moz-pre-wrap",
    whiteSpace: "-pre-wrap",
    whiteSpace: "-o-pre-wrap",
    wordWrap: "break-word"
};

class Terms extends React.Component{
    constructor(props) {
        super(props);
    }

    render(){
        return(
               <pre style={preStyle}>{`
`}<b>Privacy Policy</b>{`
This privacy policy explains our policy regarding the collection, use, disclosure and transfer of your information by andhraheadlines.com and/or its subsidiary(ies) and/or affiliate(s) and/or associate(s) (collectively referred to as the "Company"), which operates various websites and other services including but not limited to delivery of information and content via any mobile or internet connected device or otherwise (collectively the "Services"). 

The Company respects the privacy of the users of the Services and is committed to reasonably protect it in all respects. 

`}<b>1. Policy Coverage</b>{`

By accessing the websites or the Applications or otherwise using the Services, you consent to collection, storage, and use of the personal information you provide (including any changes thereto as provided by you) for any of the services that we offer. 

`}<b>2. User information and their Usage</b>{`

The information about the user as collected by the Company is: (a) information supplied by users and (b) information automatically collected/tracked while navigation (c) information collected from any other source (collectively referred to as Information).

(a) Information supplied by Users:

Subscription or paid service data:

When you chose any subscription or paid service, we or our payment gateway provider may collect your purchase, address or billing information, including your credit card number and expiration date etc. 

Voluntary information:

We may collect additional information at other times, including but not limited to, when you provide feedback, change your content or email preferences, respond to a survey, or communicate with us.

(b) Information automatically collected/tracked while navigation:

First and Third Party Cookies:

In the course of serving advertisements to our websites, either us or our advertiser may place or recognize a unique Cookie on your browser. A cookie is a piece of information stored by a web-site on your computer.  We or our partners use cookies to maintain your browsing preferences and deliver relevant ads to you. 

For best results, we suggest that you accept all cookies from andhraheadlines.com. However, if you think otherwise, check with your browser's "Internet options" to disable the cookies. 

Demographic and Purchase information:

We may also refer other sources of demographic and other information in order to provide you with more targeted communications and promotions. We use Google Analytics, among other analytics tools, to track the user behavior on our website, to support display advertising by helping us gain understanding of our users' Demographics and Interests. 

`}<b>3. Links to third party sites / ad servers:</b>{`

Our Sites and Applications may include links to other websites or applications. Such websites or applications are governed by their respective privacy policies, which are beyond our control. Once you leave our servers (you can tell where you are by checking the URL in the location bar on your browser), use of any information you provide is governed by the privacy policy of the operator of the application you are visiting. That policy may differ from ours. If you can't find the privacy policy of any of these sites via a link from the application's homepage, you should contact the application owners directly for more information. 

When we present information to our advertisers -- to help them understand our audience and confirm the value of advertising on our websites or Applications -- it is usually in the form of aggregated statistics on traffic to various pages / content within our websites or Applications. We do not provide any personally identifiable information to third party websites / advertisers / ad-servers without your consent. We also subscribe to third-party advertising companies to serve ads when you visit our websites or Applications. These companies may use information (not including your name, address, email address or telephone number or other personally identifiable information) about your visits to this and other websites or application, in order to provide advertisements about goods and services of interest to you. We do not provide any personally identifiable information to third party websites / advertisers / ad-servers without your consent.

`}<b>4. Information use by The Company:</b>{`

The Company uses information for two general purposes: to customize the advertising and content you see and to contact you about specials and new products. We will not sell or rent your personally identifiable information to third parties and we use reasonable precautions to keep the information disclosed to us secure.

Information sharing: 

The Company may disclose personal data or information or sensitive personal data about you to third parties when:

You hereby consent to share such information;
You hereby consent to share your information to provide the product or service you have requested and given your consent;
We have to cooperate with the investigations of purported unlawful activities;
When we believe in good faith that such action is necessary to protect and defend the rights and related property of the Company and that of its visitors;
Updates / Changes: 

We may alter our privacy policy from time to time to incorporate necessary changes in technology, applicable law or any other variant. In any case, we reserve the right to change (at any point of time) the terms of this Privacy Policy or the Terms of Use. Any changes we make will be effective immediately on notice, which we may give by posting the new policy on the Websites and Applications. Your use of our Services after such notice will be deemed acceptance of such changes. You are advised to review this Privacy Policy periodically to ensure that you are aware of the latest version.

`}<b>5. Disclaimer of Warranties:</b>{`

Your use of our services is at your own risk. The services are provided by the Company on an "as is" and "as available" basis. To the full extent permissible by applicable law, cyber site disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose and non-infringement. You acknowledge that any warranty that is provided in connection with any of the products or services made available on or through our websites and applications is provided solely by the owner, advertiser or manufacturer of that product and/or service and not by the Company.

The Company makes no representations or warranties of any kind, express or implied, as to the operation of the website or the information, content, materials, or products on the website, including that

Our websites and their services will meet your requirements,
Our websites will be uninterrupted, timely, secure or error free,
the quality of any products, services, information or other material purchased or obtained by you though our websites will meet your expectations, be reliable or accurate, and
errors in the software will be corrected.
Your sole remedy for dissatisfaction with our websites, site-related services and/or content or information contained within the site is to stop using the site and/or its services. Any material downloaded or otherwise obtained through the use of our websites is done at your own discretion and risk and you will be solely responsible for any damage to your computer system or loss of data that results from the download of any such material.

`}<b>6. Void Where Prohibited:</b>{`

Although the Company is accessible worldwide, not all products or services discussed or referenced on our websites are available to all persons or in all geographic locations or jurisdictions. The Company reserves the right to limit the provision of any product or service to any person, geographic area or jurisdiction it so desires, in its sole discretion and to limit the quantities of any such product or service that it provides. Any offer for any product or service made on our websites is void where prohibited.

`}<b>7. Limitation of Liability:</b>{`

In no event shall the Company be liable for any direct, indirect, incidental, special, punitive, consequential damages, or any damages whatsoever, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from

the use or the inability to use our services or access content,
the cost of procurement of substitute goods and services resulting from transactions entered into through or from our websites.
the unauthorized access to or alterations of your transmissions or data,
statements or conduct of any third party on the service, or
any other matter relating to the service, even if the Company has been advised of the possibility of damages.
`}<b>8. Indemnification:</b>{`

You agree to indemnify, defend and hold the Company harmless from all claims, damages and expenses (including attorney's fees) made by any third party arising out of your content, your use of our websites, your connection to our websites, your violation of this Agreement, our Terms of Use or our Privacy Policy, and the development, operation, maintenance, use and contents of your website.

`}<b>9. Financial Transactions and Taxes:</b>{`

If you make any financial transactions on our websites, including paying for network services fees, advertising banners, product placements, products or services, you may be asked for credit card or other payment information. You agree that all information you provide is accurate, complete and current and that you will pay all charges owed, including any applicable taxes. You agree that you are solely responsible for any personal income reporting and tax payments required of you by applicable government authorities.

`}<b>10. Termination:</b>{`

The Company reserves the right to discontinue in whole or in part any portion of the website services or programs with or without notice.                   
`}<b>11. Contact us:</b>{`

Send an email to editor@andhraheadlines.com for press release, advertisements etc. 
`}
  
               </pre> 
        );
    }
}

export default Terms;